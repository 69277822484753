import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/antonio-escritorio/Documents/moare/sites-estaticos/libres-de-violencias/src/components/default-mdx-layout.js";
import ContainerNarrow from "../components/container-narrow";
import { Box } from "theme-ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ContainerNarrow mdxType="ContainerNarrow">
      <Box sx={{
        marginTop: 6,
        h1: {
          borderBottom: theme => `1px solid ${theme.colors.primarylight}`,
          pb: 5
        }
      }} mdxType="Box">
        <h1>{`Política de privacidad`}</h1>
      </Box>
      <p>{`El responsable del tratamiento de tus datos personales es la `}<strong parentName="p">{`Asociación Entrepueblos/Entrepobles/Entrepobos/Herriarte`}</strong>{` con domicilio social en la Avenida Meridiana, 30-32, esc B, entlo 2ª, 08018 Barcelona.`}</p>
      <h2>{`¿Quién es la persona Delegada de protección de datos?`}</h2>
      <p>{`Es la persona responsable que vela por el cumplimiento de la normativa de protección de datos de carácter personal, puedes contactar con esta persona Delegada de Protección de Datos en la dirección postal de Entrepueblos/Entrepobles/Entrepobos/Herriarte, o en la dirección electrónica `}<a parentName="p" {...{
          "href": "mailto:administracion@entrepueblos.org"
        }}>{`administracion@entrepueblos.org`}</a></p>
      <h2>{`¿Qué datos personales recogemos y por qué los recogemos?`}</h2>
      <h3>{`Cookies`}</h3>
      <p>{`Usamos varias cookies propias y de terceros para ofrecer una mejor experiencia de navegación y para el funcionamiento del sitio. No obstante tienes la opción de impedir la generación de cookies y la eliminación de las mismas mediante la selección de la correspondiente opción en tu navegador. En caso de bloquear el uso de las «cookies» en su navegador, es posible que algunos servicios o funcionalidades de la página web no estén disponibles.`}</p>
      <h3>{`Analítica`}</h3>
      <p>{`Hacemos uso del servicio de estadisticas de la Google Analytics. `}<a parentName="p" {...{
          "href": "https://policies.google.com/privacy?hl=es"
        }}>{`Aqui`}</a>{` puedes leer su política de privacidad. En ningún caso estas estadísticas tratan datos de carácter personal ni desde Asociación captamos hábitos de navegación para fines publicitarios.`}</p>
      <h3>{`Contenido incrustado de otros sitios web`}</h3>
      <p>{`Los artículos de este sitio pueden incluir contenido incrustado (por ejemplo, vídeos, audios, imágenes, artículos, etc.). El contenido incrustado de otras web se comporta exactamente de la misma manera que si el visitante hubiera visitado la otra web.`}<br />{`Estas web pueden recopilar datos sobre ti, utilizar cookies, incrustar un seguimiento adicional de terceros, y supervisar tu interacción con ese contenido incrustado, incluido el seguimiento de su interacción con el contenido incrustado si tienes una cuenta y estás conectado a esa web.`}</p>
      <h2>{`Con quién compartimos tus datos`}</h2>
      <p>{`No compartimos ni cedemos tus datos a terceros. Tus datos son confidenciales y no se cederán a terceros, salvo que exista obligación legal.`}</p>
      <h2>{`¿Cuándo y por qué motivo podemos facilitar tus datos a terceros?`}</h2>
      <p>{`Tus datos podrán ser cedidos a los destinatarios que se indican a continuación, por los motivos que a continuación se explican:`}</p>
      <ul>
        <li parentName="ul">{`Administraciones Públicas: para el cumplimiento de las obligaciones legales y fiscales a las que Entrepueblos/Entrepobles/Entrepobos/Herriarte está sujeta por su actividad.`}</li>
      </ul>
      <p>{`Para más información sobre las garantías a su privacidad, podéis dirigiros a la persona Delegada de Protección de Datos, a través de las direcciones postal y electrónica indicadas.`}</p>
      <h2>{`¿Durante cuánto tiempo guardaremos sus datos?`}</h2>
      <p>{`Tus datos personales se conservarán mientras se mantenga su relación con Entrepueblos/Entrepobles/Entrepobos/Herriarte y, tras la finalización, durante los plazos de prescripción legales que sean de aplicación. En este supuesto, se tratarán a los solos efectos de acreditar el cumplimiento de nuestras obligaciones legales o contractuales. Finalizados dichos plazos de prescripción, sus datos serán eliminados o, alternativamente, anonimizados.`}</p>
      <h2>{`¿Cuáles son tus derechos?`}</h2>
      <p>{`Puedes ejercitar tus derechos de acceso, rectificación, supresión y portabilidad, limitación y/u oposición al tratamiento, a través de las direcciones postal y electrónica indicadas.`}<br />{`Asimismo, si consideras que el tratamiento de sus datos personales vulnera la normativa o sus derechos de privacidad, puedes presentar una reclamación:`}</p>
      <ul>
        <li parentName="ul">{`A nuestra persona Delegada de Protección de Datos, a través de las direcciones postal y electrónica indicadas.`}</li>
        <li parentName="ul">{`Ante la Agencia Española de Protección de Datos, a través de su sede electrónica, o de su dirección postal.`}</li>
      </ul>
    </ContainerNarrow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      